import { Checkbox, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { exportCSVProduct, getFailedProductLists } from "../apis/user";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
const { RangePicker } = DatePicker;

const FailedProductsList = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [date, setDate] = useState([]);
  const [specificDate, setSpecificDate] = useState(null);
  const dateFormat = "YYYY/MM/DD";
  // New checkbox states
  const [nutritionScorePresent, setNutritionScorePresent] = useState(false);
  const [ingredientsScorePresent, setIngredientsScorePresent] = useState(false);
  const [sustainabilityScorePresent, setSustainabilityScorePresent] =
    useState(false);

  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;
  console.log("specificDate", specificDate);
  const fetchFailedProducts = async () => {
    const data = {
      limit,
      page: currentPage,
      filterType: "all",
      // specificDate: date ? date : null,
      nutrition_score_present: nutritionScorePresent, // From checkbox state
      ingredients_score_present: ingredientsScorePresent, // From checkbox state
      sustainability_score_present: sustainabilityScorePresent, // From checkbox state
      startDate: date.length > 0 ? date[0] : null,
      endDate: date.length > 0 ? date[1] : null,
      product_id: searchInput,
    };
    console.log("data", data);
    showLoader();
    dispatch(getFailedProductLists(data))
      .then((res) => {
        if (res.status) {
          setUserData(res.data.data.data);
          setLimit(res?.data?.data?.limit);
          setCurrentPage(res?.data?.data?.page);
          setTotalItems(res.data.data.totalItems);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchFailedProducts();
  }, [
    limit,
    currentPage,
    date,
    specificDate,
    nutritionScorePresent,
    ingredientsScorePresent,
    sustainabilityScorePresent,
    searchInput,
  ]);

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      sorter: (a, b) =>
        (a.user_name &&
          b.user_name &&
          a.user_name.localeCompare(b.user_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      sorter: (a, b) =>
        (a.user_email &&
          b.user_email &&
          a.user_email.localeCompare(b.user_email)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
      sorter: (a, b) =>
        (a.product_id &&
          b.product_id &&
          a.product_id.localeCompare(b.product_id)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) =>
        (a.product_name &&
          b.product_name &&
          a.product_name.localeCompare(b.product_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) =>
        (a.brand_name &&
          b.brand_name &&
          a.brand_name.localeCompare(b.brand_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a, b) =>
        (a.barcode && b.barcode && a.barcode.localeCompare(b.barcode)) || 0,
      ellipsis: true,
      render: (text) => (
        <>
          {text.trim() !== "" ? (
            <CutOffText maxLength={20} value={text} />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Nutrition",
      dataIndex: "nutrition_present",
      key: "nutrition_present",
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Ingredient",
      dataIndex: "ingredients_present",
      key: "ingredients_present",
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Sustainability",
      dataIndex: "sustainability_present",
      key: "sustainability_present",
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Overall Status",
      dataIndex: "overall_status",
      key: "overall_status",
      ellipsis: true,
      render: (text) => <div className="capitalize">{text}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (text) => <>{text ? text : "-"}</>,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
  };

  const handleExportCSV = async () => {
    const data = {
      fields: [
        "name",
        "email",
        "gender",
        "sign_up_date",
        "last_login",
        "blocked",
      ],
    };
    try {
      const response = await dispatch(exportCSVProduct(data));
      if (response.status) {
        console.log("response.data", response.data.data);
        window.open(response.data.data, "_blank");
      } else {
        console.log("response", response);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <CustomLayout>
      {/* Filters */}
      <div className="mb-4 flex items-center">
        {/* <Select
          defaultValue={date}
          value={date}
          onChange={(value) => setDate(value)}
          placeholder="Select Date"
          options={failedProductTime.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          className="w-[200px] mr-[20px]"
        /> */}
        <RangePicker
          className="mr-[20px]"
          onChange={(date, dateStrings) => {
            setSpecificDate(date);
            setDate(dateStrings);
            console.log("date Range Picker", date, dateStrings);
          }}
          format={dateFormat}
        />
        {/* <DatePicker
          className="mr-[20px]"
          value={specificDate}
          onChange={(date, dateString) => {
            setSpecificDate(date);
            setDate(dateString);
          }}
          placeholder="Select Specific Date"
          format={dateFormat}
        /> */}
        <label className="mr-4">
          <Checkbox
            checked={nutritionScorePresent}
            onChange={(e) => setNutritionScorePresent(e.target.checked)}
          >
            Nutrition Score
          </Checkbox>
        </label>
        <label className="mr-4">
          <Checkbox
            checked={ingredientsScorePresent}
            onChange={(e) => setIngredientsScorePresent(e.target.checked)}
          >
            Ingredients Score
          </Checkbox>
        </label>
        <label>
          <Checkbox
            checked={sustainabilityScorePresent}
            onChange={(e) => setSustainabilityScorePresent(e.target.checked)}
          >
            Sustainability Score
          </Checkbox>
        </label>
      </div>

      <CommonTable
        apiData={userData && userData}
        columns={columns}
        currentPage={currentPage}
        itemsPerPage={limit}
        onPageChange={handlePageChange}
        onShowSizeChange={handleSizeChange}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        search={true}
        totalItems={totalItems}
        filter={true}
        showCSV={true}
        onExportCSV={handleExportCSV}
      />
    </CustomLayout>
  );
};

export default withAuth(FailedProductsList);
