import { Typography } from "antd";
import GradientRangeData from "./GradientRangeData";
import MoreInformation from "./MoreInformation";
import GradientDataRang from "./GradientDataRang";

const Template2 = ({ values, per_serving }) => {
  console.log("values", values, per_serving);
  const dietaryFiberScore = values[Object.keys(values)[0]];
  console.log("dietaryFiberScore", dietaryFiberScore);

  const references =
    dietaryFiberScore?.reference_url?.trim() &&
    dietaryFiberScore?.reference_url?.split(/[,|]/)?.map((ref) => {
      const colonIndex = ref.indexOf(":");
      if (colonIndex !== -1) {
        const label = ref?.substring(0, colonIndex)?.trim();
        const url = ref?.substring(colonIndex + 1)?.trim();
        return { label, url };
      } else {
        return { label: "", url: ref?.trim() };
      }
    });

  const key = Object.keys(values)[0] && Object.keys(values)[0];
  const showGradientRangeDataFor = [
    "protein",
    "fiber",
    "sodium",
    "saturated_fat",
    "total_sugar",
    "calories",
    "fruit/veggies/nuts%",
  ];

  return (
    <div className="no-scrollbar max-h-[350px] max-w-[470.63px] flex flex-col gap-[15px] px-[13px] py-[10px] rounded-[13px] border overflow-x-hidden overflow-y-auto">
      {values?.templateType === 5 ? (
        <>
          {values?.templateType === 5 && (
            <div>
              <GradientDataRang
                templateData={values}
                servingSize={per_serving ? per_serving.toFixed(1) : 0}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="capitalize flex justify-center items-start text-[#2F2F2F] font-[700] text-[18px] leading-[15.78px] ">
            {key
              ? key
                  ?.replace(/_/g, " ")
                  ?.replace(/\b\w/g, (char) => char?.toUpperCase())
              : "ScoriFi Range"}
          </div>
          {showGradientRangeDataFor.includes(key) ? (
            <GradientRangeData
              templateData={values}
              servingSize={per_serving ? per_serving.toFixed(1) : 0}
            />
          ) : (
            (dietaryFiberScore?.itag_template === "template-1" ||
              dietaryFiberScore?.itag_template === "template-2") && (
              <>
                <div>
                  <GradientRangeData
                    templateData={values}
                    servingSize={per_serving ? per_serving.toFixed(1) : 0}
                  />
                </div>
              </>
            )
          )}
          {dietaryFiberScore && (
            <MoreInformation
              moreInfoType={
                dietaryFiberScore?.itag_template === "template-3"
                  ? typeof dietaryFiberScore?.more_information === "string"
                    ? "string"
                    : "array"
                  : "string"
              }
              templateData={dietaryFiberScore}
              seeMore={false}
            />
          )}
          {dietaryFiberScore?.reference_url && (
            <div className="p-[1px] bg-slate-200 mb-4" />
          )}
          {references && references?.length > 0 && (
            <>
              <div className="flex flex-col lg:flex-row items-start lg:items-center gap-1 w-full">
                <div>
                  <Typography.Text className="text-[#1D1D1D] font-[700] text-[14px] leading-[16.8px] capitalize">
                    Ref:
                  </Typography.Text>
                </div>
                <div>
                  {dietaryFiberScore?.reference_url &&
                    references?.length > 0 && (
                      <div className="font-[500] text-[14px] leading-[15.78px]">
                        <div className="text-[#2F2F2F] font-[500] text-[14px] leading-[18.41px] capitalize">
                          {references.map((ref) => ref.label).join(", ")}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Template2;
