export const login = "/auth/login";
export const forgotPassword = "/auth/forgot-password";
export const validateToken = "/auth/check-forgot-password-token";
export const getCustomer = "/admin/get-customers";
export const blockUserId = "/admin/block-user";
export const unBlockUserId = "/admin/unblock-user";
export const getUserFile = "/admin/export-customers";
export const getDashboardUsers = "/admin/get-dashboard-data";
export const getSubscriptionGraphUsersApi = "/admin/get-subscription-user";
export const getHistoryDataRoute = "/admin/get-users-history";
export const getSummaryChartsRoute = "/admin/get-product-scoring-analysis";
// plan and pricing

export const getPlanPrice = "/user/plan-prices";
export const addPlanPrice = "/admin/add-plan-price";
export const removePlanPrice = "/admin/remove-plan-price";
export const editPlanPrice = "/admin/update-plan-price";

// Subscription

export const userSubscription = "/admin/user-subscriptions";

// History
export const historyByUserId = "/admin/get-user-history";
export const historyDetails = "/admin/get-user-history-data";

// Failed Product List

export const failedProductList = "/admin/failed-products-list";
export const failedProductCSV = "/admin/failed-products-list-export";
