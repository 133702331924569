import React, { useState } from "react";

const MoreInformation = ({ templateData, moreInfoType }) => {
  console.log("templateData, moreInfoType", templateData, moreInfoType);
  const [expanded, setExpanded] = useState(false);

  const sections =
    moreInfoType === "string"
      ? templateData?.more_information.split("|||")
      : templateData?.more_information;

  if (sections?.length === 1 || moreInfoType !== "string") {
    const singleSection =
      moreInfoType === "string" ? sections[0].split("|") : sections;
    const filteredSection = singleSection?.filter((info) => info.trim() !== "");

    return (
      <div className="mb-2.5 mt-1.25 flex flex-col items-center">
        {filteredSection &&
          filteredSection.map((info, index) => (
            <div key={index} className="flex flex-row self-start mr-2.5 gap-1">
              <span className="text-lg text-title-color mt-0.75">•</span>
              <span className="text-lg text-title-color mt-0.75 text-justify">
                {info.trim()}
              </span>
            </div>
          ))}
      </div>
    );
  }

  const firstSection = sections[0]?.split("|");
  const secondSection = sections[1]?.split("|");

  return (
    <div className="mb-2.5 mt-1.25 flex flex-col items-center">
      {firstSection[0] != "" &&
        firstSection?.map((info, index) => (
          <div key={index} className="flex flex-row self-start mr-2.5 gap-1">
            <span className="text-[14px] text-title-color mt-0.75">•</span>
            <span
              key={index}
              className="text-[14px] text-title-color mt-0.75 text-justify"
            >
              {info?.trim()}
            </span>
          </div>
        ))}

      {expanded &&
        secondSection?.map((info, index) => (
          <div key={index} className="flex flex-row self-start mr-2.5 gap-1">
            <span className="text-[14px] text-title-color mt-0.75">• </span>
            <span
              key={index}
              className="text-[14px] text-title-color mt-0.75 text-justify"
            >
              {info?.trim()}
            </span>
          </div>
        ))}
      <div className="flex justify-end w-full">
        <button
          className="text-[#FBAE3B] mt-2"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "See Less" : "See More"}
        </button>
      </div>
    </div>
  );
};

export default MoreInformation;
