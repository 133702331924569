import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHistoryData, historyDetailsByUserID } from "../apis/user";
import ViewProductDetails from "../components/ViewProductDetails";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import TableAction from "../components/common/TableAction";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";

const History = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const dateFormat = "YYYY/MM/DD";
  const [date, setDate] = useState(null);
  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;
  const data = {
    page: currentPage,
    limit: limit,
    searchQuery: searchInput.trim(),
    // date: date,
    start_date: date?.length > 0 ? date[0] : null,
    end_date: date?.length > 0 ? date[1] : null,
  };
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [product, setProduct] = useState([]);
  const fetchUserData = async () => {
    setUserData(null);
    showLoader();
    dispatch(getHistoryData(data))
      .then((res) => {
        if (res.status) {
          setUserData(res.data.data.history);
          setLimit(res.data.data.limit);
          setCurrentPage(res.data.data.page);
          setTotalItems(res.data.data.total);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [limit, currentPage, searchInput, date]);
  console.log("date", date);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name && b.name && a.name.localeCompare(b.name)) || 0,
      render: (text) => <>{<CutOffText maxLength={20} value={text} />}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text) => <CutOffText maxLength={15} value={text} />,
      sorter: (a, b) =>
        (a.email && b.email && a.email.localeCompare(b.email)) || 0,
    },
    {
      title: "Last Question",
      dataIndex: "last_question",
      key: "last_question",
      render: (text) => <>{<CutOffText maxLength={20} value={text} />}</>,
    },
    {
      title: <CutOffText maxLength={20} value={"No. of Questions Asked"} />,
      dataIndex: "questionsCount",
      key: "questionsCount",
      render: (text) => <>{!text ? 0 : text}</>,
    },
    {
      title: <CutOffText maxLength={20} value={"No. of Liked Results"} />,
      dataIndex: "liked_responses",
      key: "liked_responses ",
      render: (text) => <>{!text ? 0 : text}</>,
    },
    {
      title: <CutOffText maxLength={20} value={"No. of Disliked Results"} />,
      dataIndex: "disliked_responses",
      key: "disliked_responses",
      render: (text) => <>{!text ? 0 : text}</>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <>{!text ? 0 : text}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <TableAction view={true} onView={() => handleView(record)} />
        </Space>
      ),
    },
  ];

  const handleView = async (record) => {
    console.log("record", record);
    const data = {
      user_id: record?.id,
      history_id: record?.history_id,
    };
    showLoader();
    try {
      if (!record?.history_id || !record?.id) {
        hideLoader();
        return;
      }
      const response = await historyDetailsByUserID(data);
      if (response.status) {
        setShowProductDetails(true);
        setProduct(response.data);
        hideLoader();
      } else {
        setShowProductDetails(false);
        setProduct([]);
        hideLoader();
      }
    } catch (error) {
      console.log("error", error);
      hideLoader();
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
  };

  return (
    <CustomLayout>
      {showProductDetails ? (
        <ViewProductDetails
          product={product}
          setShowProductDetails={setShowProductDetails}
        />
      ) : (
        <>
          <CommonTable
            apiData={userData && userData}
            columns={columns}
            currentPage={currentPage}
            itemsPerPage={limit}
            onPageChange={handlePageChange}
            onShowSizeChange={handleSizeChange}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            totalItems={totalItems}
            setDate={setDate}
            dateFilter={true}
            datepickerType="RangePicker"
            dateFormat={dateFormat}
          />
        </>
      )}
    </CustomLayout>
  );
};

export default withAuth(History);
