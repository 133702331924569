import { CaretDownOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import {
  interpolateColorNagitive,
  RangeColorCode,
} from "../../utils/searchPrompt";

const GradientDataRang = ({ templateData, servingSize }) => {
  console.log("templateData in", templateData);

  const [colors, setColors] = useState([]);

  useEffect(() => {
    colorCode();
  }, []);

  const colorCode = () => {
    const colorStart = interpolateColorNagitive(0, 61, "positive");
    const colorEnd = interpolateColorNagitive(62, 100, "positive");
    setColors((prev) => [...prev, ...colorStart, ...colorEnd]);
  };

  return (
    <div className="rangeBorderContainer w-full">
      <div className="flex flex-col gap-[2px] items-center justify-center w-full mb-[15px]">
        <div className="font-[600] text-[12px] leading-[14.4px] text-[#09694A]">
          ScoriFi Score
        </div>
      </div>
      <div className="colorRangeContainer w-[200px]">
        {RangeColorCode?.map((item, index) => {
          return (
            <div key={index} style={{ flex: 1, position: "relative" }}>
              {item?.score === templateData.data.score && (
                <div
                  className="mainDiv"
                  style={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    left: item?.score == templateData.data.score ? 2 : 0,
                    right: item?.score == templateData.data.score ? 2 : 0,
                  }}
                >
                  <div className={`indicter w-[60px] flex flex-col !ml-[-8px]`}>
                    <Typography.Text className={`w-full text-[12px] `}>
                      {templateData.data.score}
                    </Typography.Text>
                    <CaretDownOutlined style={{ color: colors[index] }} />
                  </div>
                </div>
              )}

              <div
                style={{
                  justifyContent: "flex-end",
                  marginTop: 40,
                }}
              ></div>

              {item?.score === 6 && (
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333",
                    // backgroundColor: "red",
                    width: "100%",
                    bottom: -25,
                    fontSize: 12,
                    paddingLeft: item?.score == 6 ? 2 : 0,
                    paddingRight: item?.score == 6 ? 2.5 : 0,
                  }}
                >
                  <span className="">6</span>
                </div>
              )}

              <div
                style={{
                  height: 10,
                  backgroundColor: colors[index],
                  marginLeft: item?.score == 6 ? 1.5 : 0,
                  marginRight: item?.score == 6 ? 1.5 : 0,
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="scoreRangeView">
        <div className="scoreTypeText">0</div>
        <div className="scoreTypeText">10</div>
      </div>
    </div>
  );
};

export default GradientDataRang;
