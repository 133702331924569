import { CaretDownOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import {
  calculateBlockValues,
  divideIntoTwoParts,
  interpolateColor,
  interpolateColorNagitive,
  PositiveCut,
  RangeColorCode,
  roundedScoreCut,
  roundedScoreIndicateMatch,
  roundedScoreMatch,
  roundedScoreValue,
} from "../../utils/searchPrompt";

const GradientRangeData = ({ templateData, servingSize }) => {
  console.log("templateData in", templateData);
  const [paramType, setParamType] = useState("positive");
  const title = Object.keys(templateData)[0]
    ?.replace(/_/g, " ")
    ?.replace(/\b\w/g, (char) => char.toUpperCase());

  const templateDataValues = templateData[Object.keys(templateData)[0]];
  console.log("templateDataValues", templateDataValues);
  const templateTitle =
    Object.keys(templateData)[0] && Object.keys(templateData)[0];

  const [colors, setColors] = useState([]);

  useEffect(() => {
    colorCode();
  }, []);

  const colorCode = () => {
    if (templateDataValues?.more_info_visual?.start_better_range === 0) {
      setParamType("negative");
      const colorStart = interpolateColorNagitive(100, 61, "negative");
      const colorEnd = interpolateColorNagitive(60, 0, "negative");
      setColors((prev) => [...prev, ...colorStart, ...colorEnd]);
    } else if (
      templateDataValues?.more_info_visual?.end_better_range ===
      templateDataValues?.more_info_visual?.end_data
    ) {
      // let range = RangeColorCode?.find(
      //   (range) =>
      //     range.score >=
      //     roundedScoreMatch(
      //       templateDataValues?.more_info_visual?.start_better_range,
      //       templateDataValues?.more_info_visual?.end_data
      //     )
      // );
      // const lastColor = 101 - range?.id;
      // const valueBlock = calculateBlockValues(101, lastColor);
      // const colorEnd = interpolateColor(
      //   RangeColorCode[51].colorCode,
      //   RangeColorCode[100].colorCode,
      //   51
      //   // lastColor
      // );
      // // const colorMid = interpolateColor(
      // //   RangeColorCode[67].colorCode,
      // //   RangeColorCode[79].colorCode,
      // //   valueBlock[2]
      // // );
      // const colorStart = interpolateColor(
      //   RangeColorCode[40].colorCode,
      //   RangeColorCode[50].colorCode,
      //   50
      //   // valueBlock[1] + valueBlock[2]
      // );
      // setColors((prev) => [...prev, ...colorStart, ...colorEnd]);
      setParamType("positive");
      const colors = [];
      for (let i = 40; i <= 100; i++) {
        colors.push(RangeColorCode[i].colorCode);
      }
      setColors((prev) => [...prev, ...colors]);
    } else {
      let rangeStart = RangeColorCode.find(
        (range) =>
          range.score >=
          roundedScoreMatch(
            templateDataValues?.more_info_visual?.start_better_range,
            templateDataValues?.more_info_visual?.end_data
          )
      );
      let rangeEnd = RangeColorCode.find(
        (range) =>
          range.score >=
          roundedScoreMatch(
            templateDataValues?.more_info_visual?.end_better_range,
            templateDataValues?.more_info_visual?.end_data
          )
      );
      const middleValue = 101 - (rangeStart.id + 101 - rangeEnd.id);
      const valueBlock = divideIntoTwoParts(middleValue);
      const colorMid1 = interpolateColor(
        RangeColorCode[70].colorCode,
        RangeColorCode[100].colorCode,
        valueBlock[0]
      );
      const colorMid2 = interpolateColor(
        RangeColorCode[100].colorCode,
        RangeColorCode[70].colorCode,
        valueBlock[1]
      );
      const colorStart = interpolateColor(
        RangeColorCode[0].colorCode,
        RangeColorCode[49].colorCode,
        rangeStart.id
      );
      const colorEnd = interpolateColor(
        RangeColorCode[49].colorCode,
        RangeColorCode[0].colorCode,
        rangeEnd.id
      );
      setColors((prev) => [
        ...prev,
        ...colorStart,
        ...colorMid1,
        ...colorMid2,
        ...colorEnd,
      ]);
    }
  };
  console.log("servingSize", servingSize);
  return (
    <div className="rangeBorderContainer w-full">
      <div className="flex flex-col gap-[2px] items-center justify-center w-full mb-[15px]">
        <div className="font-[600] text-[12px] leading-[14.4px] text-[#09694A]">
          Better Range:{" "}
          {roundedScoreValue(
            templateDataValues?.more_info_visual?.start_better_range
          )}
          {`${templateDataValues?.unit ?? "g"}`}-
          {roundedScoreValue(
            templateDataValues?.more_info_visual?.end_better_range
          )}
          {`${templateDataValues?.unit ?? "g"}`}
        </div>
        <div className="font-[600] ml-1 text-[12px] leading-[14.4px] mt-[0.7px] text-[#09694A]">
          {`For Serving size-${parseInt(servingSize)}g`}
        </div>
      </div>
      <div className="colorRangeContainer">
        {(paramType === "positive" &&
          RangeColorCode?.slice(40, 101)?.map((item, index) => {
            const idEnd =
              templateDataValues?.value >=
              templateDataValues?.more_info_visual?.end_better_range;
            console.log(
              "templateDataValues 2",
              item?.score === Number(templateDataValues?.score),
              item?.score,
              Number(templateDataValues?.score)
            );
            return (
              <div key={index} style={{ flex: 1, position: "relative" }}>
                {item?.score === Number(templateDataValues?.score) && (
                  <div
                    className="mainDiv"
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      left: item?.score === PositiveCut ? 2 : 0,
                      right: item?.score == 10 ? 2 : 0,
                    }}
                  >
                    <div
                      className={`indicter w-[60px] flex flex-col 
                      ${idEnd ? "!ml-[-8px]" : "!ml-[-10px]"}
                      `}
                    >
                      <Typography.Text
                        className={`w-full text-[12px] ${
                          templateDataValues?.unit == "kcal" && "ml-[-12px]"
                        }`}
                      >
                        {`${roundedScoreValue(templateDataValues?.value)}${
                          templateDataValues?.unit
                        }`}
                      </Typography.Text>
                      <CaretDownOutlined style={{ color: colors[index] }} />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    justifyContent: "flex-end",
                    marginTop: 40,
                  }}
                ></div>

                {item?.score === PositiveCut && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignSelf: "center",
                      alignItems: "center",
                      color: "#333",
                      width: 70,
                      bottom: -25,
                      fontSize: 12,
                      paddingLeft: item?.score === PositiveCut ? 2 : 0,
                      paddingRight: item?.score == 10 ? 2.5 : 0,
                    }}
                  >
                    <span className="ml-[-9px]">
                      {`${roundedScoreValue(
                        templateDataValues?.more_info_visual?.start_better_range
                      )}${templateDataValues?.unit}`}
                    </span>
                  </div>
                )}

                <div
                  style={{
                    height: 10,
                    backgroundColor: colors[index],
                    marginLeft: item?.score === PositiveCut ? 2.5 : 0,
                    marginRight: item?.score == 10 ? 2.5 : 0,
                  }}
                />
              </div>
            );
          })) ||
          RangeColorCode?.map((item, index) => {
            const idEnd =
              templateDataValues?.value >=
              templateDataValues?.more_info_visual?.end_data;
            return (
              <div key={index} style={{ flex: 1, position: "relative" }}>
                {item?.score ===
                  (templateDataValues?.more_info_visual?.start_better_range ===
                  0
                    ? roundedScoreIndicateMatch(
                        templateDataValues?.score,
                        templateDataValues?.value,
                        templateDataValues?.more_info_visual?.end_data
                      )
                    : templateDataValues?.value >=
                        templateDataValues?.more_info_visual?.end_data ||
                      templateDataValues?.score === 10
                    ? templateDataValues?.score
                    : templateDataValues?.score + 0.5) && (
                  <div
                    className="mainDiv"
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      left:
                        item?.score ==
                        roundedScoreMatch(
                          templateDataValues?.more_info_visual
                            ?.start_better_range,
                          templateDataValues?.more_info_visual?.end_data
                        )
                          ? 2
                          : 0,
                      right:
                        item?.score ==
                        roundedScoreMatch(
                          templateDataValues?.more_info_visual
                            ?.end_better_range,
                          templateDataValues?.more_info_visual?.end_data
                        )
                          ? 2
                          : 0,
                    }}
                  >
                    <div
                      className={`indicter w-[60px] flex flex-col ${
                        idEnd
                          ? `${
                              templateDataValues?.value ==
                              templateDataValues?.more_info_visual?.end_data
                                ? "ml-[0px]"
                                : "!ml-[-8px]"
                            }`
                          : "!ml-[-10px]"
                      }`}
                    >
                      <Typography.Text
                        className={`w-full text-[12px] ${
                          templateDataValues?.unit == "kcal" &&
                          templateDataValues?.value !=
                            templateDataValues?.more_info_visual?.end_data &&
                          "ml-[-12px]"
                        }`}
                      >
                        {`${roundedScoreValue(templateDataValues?.value)}${
                          templateDataValues?.unit
                        }`}
                      </Typography.Text>
                      <CaretDownOutlined
                        style={{
                          color: colors[index],
                          marginLeft:
                            templateDataValues?.value ==
                            templateDataValues?.more_info_visual?.end_data
                              ? 20
                              : 0,
                        }}
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    justifyContent: "flex-end",
                    marginTop: 40,
                  }}
                ></div>

                {item?.score ===
                  Number(
                    parseFloat(
                      10 -
                        roundedScoreCut(
                          templateDataValues?.more_info_visual
                            ?.start_better_range
                        )
                    ).toFixed(1)
                  ) && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      alignSelf: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#333",
                      // backgroundColor: "red",
                      width: "100%",
                      bottom: -25,
                      fontSize: 12,
                      paddingLeft:
                        item?.score ==
                        roundedScoreMatch(
                          templateDataValues?.more_info_visual
                            ?.start_better_range,
                          templateDataValues?.more_info_visual?.end_data
                        )
                          ? 2
                          : 0,
                      paddingRight:
                        item?.score ==
                        roundedScoreMatch(
                          templateDataValues?.more_info_visual
                            ?.end_better_range,
                          templateDataValues?.more_info_visual?.end_data
                        )
                          ? 2.5
                          : 0,
                    }}
                  >
                    <span className="ml-[-9px]">
                      {`${roundedScoreValue(
                        templateDataValues?.more_info_visual
                          ?.start_better_range !== 0
                          ? templateDataValues?.more_info_visual
                              ?.start_better_range
                          : templateDataValues?.more_info_visual
                              ?.end_better_range
                      )}${templateDataValues?.unit}`}
                    </span>
                  </div>
                )}

                <div
                  style={{
                    height: 10,
                    backgroundColor: colors[index],
                    marginLeft:
                      item?.score ==
                      Number(
                        10 -
                          roundedScoreCut(
                            templateDataValues?.more_info_visual
                              ?.start_better_range
                          )
                      ).toFixed(1)
                        ? 1.5
                        : 0,
                    marginRight:
                      item?.score ==
                      Number(
                        10 -
                          roundedScoreCut(
                            templateDataValues?.more_info_visual
                              ?.start_better_range
                          )
                      ).toFixed(1)
                        ? 1.5
                        : 0,
                  }}
                />
              </div>
            );
          })}
      </div>

      <div className="scoreRangeView">
        <div className="scoreTypeText">
          {`${roundedScoreValue(
            templateDataValues?.more_info_visual?.start_data
          )}${templateDataValues?.unit ?? "g"}`}
        </div>
        <div className="scoreTypeText">
          {`${roundedScoreValue(
            templateDataValues?.more_info_visual?.end_data
          )}${templateDataValues?.unit}`}
          {templateDataValues?.value >
            roundedScoreValue(templateDataValues?.more_info_visual?.end_data) &&
            "+"}
        </div>
      </div>
    </div>
  );
};

export default GradientRangeData;
