// import {
//   Badge,
//   Checkbox,
//   Image,
//   Popover,
//   Space,
//   Spin,
//   Tag,
//   Typography,
// } from "antd";
// import React, { useState } from "react";
// import Like from "../../assets/images/Like.png";
// import LikeIcon from "../../assets/images/LikeIcon.png";
// import info from "../../assets/images/info.png";
// import useScreenWidth from "../../utils/ScreenWidth";
// import {
//   getColorCode,
//   matchLabelColor,
//   matchTextColor,
//   roundNumber,
//   sanitizeProductName,
// } from "../../utils/searchPrompt";
// import { BackIcon } from "../Icons/BackIcon";
// import DownIcon from "../Icons/DownIcon";
// import { RightIcon } from "../Icons/RightIcon";
// import { UpIcon } from "../Icons/UpIcon";
// import Template1 from "../Templates/Template1";
// import Template2 from "../Templates/Template2";
// import CutOffText from "../common/CutOffText";
// import { LoadingOutlined } from "@ant-design/icons";

// const ViewProductDetails = ({ product, setShowProductDetails }) => {
//   const screenWidth = useScreenWidth();
//   const [visibleSections, setVisibleSections] = useState({});
//   const [visibleReviews, setVisibleReviews] = useState({});

//   const toggleSectionVisibility = (productIndex, sectionIndex) => {
//     console.log("productIndex, sectionIndex", productIndex, sectionIndex);
//     const key = `${productIndex}-${sectionIndex}`;
//     setVisibleSections((prevState) => ({
//       ...prevState,
//       [key]: !prevState[key],
//     }));
//   };

//   const toggleReviewVisibility = (productIndex) => {
//     const key = `${productIndex}-reviews`;
//     setVisibleReviews((prevState) => ({
//       ...prevState,
//       [key]: !prevState[key],
//     }));
//   };

//   const titleContent = (value) => <Template1 value={value} />;
//   const content = (value) => {
//     if (value[Object.keys(value)[0]]?.itag_template) {
//       return <Template2 values={value} />;
//     } else {
//       return false;
//     }
//   };

//   return (
//     <div>
//       <div className="titlediv flex justify-between pt-[10px] pb-[30px]">
//         <Space>
//           <BackIcon
//             onClick={() => setShowProductDetails(false)}
//             className="cursor-pointer hover:text-[blue]"
//           />

//           <div className="flex justify-between text-gray-700 font-baijamjuree text-[22px] font-semibold leading-6">
//             Search Details
//           </div>
//         </Space>
//       </div>

//       <div>
//         {product?.map((item, index) => {
//           const formattedScore =
//             typeof item?.response_scoring?.score === Number
//               ? item?.response_scoring?.score
//               : Number(item?.response_scoring?.score);
//           const isScored =
//             item?.response_type === "with_scoring" &&
//             item?.scoring_inprogress == false &&
//             item?.state == "scored";
//           const isScoredInProgress =
//             item?.response_type === "with_scoring" &&
//             item?.scoring_inprogress == true;
//           const isScoredPending =
//             item?.response_type === "with_scoring" &&
//             item?.scoring_inprogress == false &&
//             item?.state == "pending";

//           const isScoredFailed =
//             item?.response_type === "with_scoring" &&
//             item?.scoring_inprogress == false &&
//             item?.state == "failed";

//           const isTextScored =
//             item?.response_type === "without_scoring" &&
//             item?.scoring_inprogress == false &&
//             item?.state === "scored";
//           const isTextScoredInProgress =
//             item?.response_type === "without_scoring" &&
//             (item?.input_type === "text" || item?.input_type === "barcode") &&
//             item?.state === "inprogress";

//           const isTextScoredPending =
//             item?.response_type === "without_scoring" &&
//             (item?.input_type === "text" || item?.input_type === "barcode") &&
//             item?.state === "pending";

//           const isTextScoredFailed =
//             (item?.response_type === "without_scoring" &&
//               (item?.input_type === "text" || item?.input_type === "barcode") &&
//               item?.state == "failed") ||
//             ((item?.input_type === "text" || item?.input_type === "barcode") &&
//               (item?.state == "failed" || item?.state === "scored") &&
//               item?.response_scoring == null);
//           console.log(
//             "status :::",
//             isScored,
//             isScoredInProgress,
//             isScoredPending,
//             isScoredFailed,
//             isTextScored,
//             isTextScoredInProgress,
//             isTextScoredPending,
//             isTextScoredFailed,
//             item?.product_details?.product_name
//           );
//           return (
//             <>
//               {item?.response_type === "with_scoring" ? (
//                 <>
//                   {isScored && (
//                     <>
//                       isScored{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </>
//                   )}

//                   {isScoredInProgress && (
//                     <>
//                       isScoredInProgress{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </>
//                   )}

//                   {isScoredPending && (
//                     <>
//                       isScoredPending{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </>
//                   )}

//                   {isScoredFailed && (
//                     <>
//                       isScoredFailed{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </>
//                   )}
//                 </>
//               ) : (
//                 <div key={index}>
//                   {isTextScored && (
//                     <h1>
//                       isTextScored{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </h1>
//                   )}

//                   {isTextScoredInProgress && (
//                     <h1>
//                       isTextScoredInProgress{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </h1>
//                   )}

//                   {isTextScoredPending && (
//                     <h1>
//                       isTextScoredPending{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </h1>
//                   )}

//                   {isTextScoredFailed && (
//                     <h1>
//                       isTextScoredFailed{" "}
//                       <span className="font-bold">
//                         {item?.product_details?.product_name}
//                       </span>
//                     </h1>
//                   )}
//                 </div>
//               )}
//             </>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default ViewProductDetails;

import {
  Badge,
  Checkbox,
  Image,
  Popover,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import Like from "../../assets/images/Like.png";
import LikeIcon from "../../assets/images/LikeIcon.png";
import info from "../../assets/images/info.png";
import useScreenWidth from "../../utils/ScreenWidth";
import {
  getColorCode,
  matchLabelColor,
  matchTextColor,
  roundNumber,
  sanitizeProductName,
} from "../../utils/searchPrompt";
import { BackIcon } from "../Icons/BackIcon";
import DownIcon from "../Icons/DownIcon";
import { RightIcon } from "../Icons/RightIcon";
import { UpIcon } from "../Icons/UpIcon";
import Template1 from "../Templates/Template1";
import Template2 from "../Templates/Template2";
import CutOffText from "../common/CutOffText";
import { LoadingOutlined } from "@ant-design/icons";

const ViewProductDetails = ({ product, setShowProductDetails }) => {
  const screenWidth = useScreenWidth();
  const [visibleSections, setVisibleSections] = useState({});
  const [visibleReviews, setVisibleReviews] = useState({});

  const toggleSectionVisibility = (productIndex, sectionIndex) => {
    console.log("productIndex, sectionIndex", productIndex, sectionIndex);
    const key = `${productIndex}-${sectionIndex}`;
    setVisibleSections((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleReviewVisibility = (productIndex) => {
    const key = `${productIndex}-reviews`;
    setVisibleReviews((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const titleContent = (value) => <Template1 value={value} />;
  const content = (value) => {
    if (value[Object.keys(value)[0]]?.itag_template) {
      return <Template2 values={value} />;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="titlediv flex justify-between pt-[10px] pb-[30px]">
        <Space>
          <BackIcon
            onClick={() => setShowProductDetails(false)}
            className="cursor-pointer hover:text-[blue]"
          />

          <div className="flex justify-between text-gray-700 font-baijamjuree text-[22px] font-semibold leading-6">
            Search Details
          </div>
        </Space>
      </div>

      <div>
        {product?.map((item, index) => {
          const formattedScore =
            typeof item?.response_scoring?.score === Number
              ? item?.response_scoring?.score
              : Number(item?.response_scoring?.score);
          const isScored =
            item?.response_type === "with_scoring" &&
            item?.scoring_inprogress == false &&
            item?.state == "scored";
          const isScoredInProgress =
            item?.response_type === "with_scoring" &&
            item?.scoring_inprogress == true;
          const isScoredPending =
            item?.response_type === "with_scoring" &&
            item?.scoring_inprogress == false &&
            item?.state == "pending";

          const isScoredFailed =
            item?.response_type === "with_scoring" &&
            item?.scoring_inprogress == false &&
            item?.state == "failed";

          const isTextScored =
            item?.response_type === "without_scoring" &&
            item?.scoring_inprogress == false &&
            item?.state === "scored";
          const isTextScoredInProgress =
            item?.response_type === "without_scoring" &&
            (item?.input_type === "text" || item?.input_type === "barcode") &&
            item?.state === "inprogress";

          const isTextScoredPending =
            item?.response_type === "without_scoring" &&
            (item?.input_type === "text" || item?.input_type === "barcode") &&
            item?.state === "pending";
          const inPutTypeBarcode = item?.input_type == "barcode";

          const isTextScoredFailed =
            (item?.response_type === "without_scoring" &&
              (item?.input_type === "text" || item?.input_type === "barcode") &&
              item?.state == "failed") ||
            ((item?.input_type === "text" || item?.input_type === "barcode") &&
              (item?.state == "failed" || item?.state === "scored") &&
              item?.response_scoring == null);
          console.log(
            "status :::",
            isScored,
            isScoredInProgress,
            isScoredPending,
            isScoredFailed,
            isTextScored,
            isTextScoredInProgress,
            isTextScoredPending,
            isTextScoredFailed,
            item?.product_details?.product_name
          );
          return (
            <>
              {item?.response_type === "with_scoring" ? (
                <>
                  {isScored && (
                    <div className="my-3">
                      <div
                        key={index}
                        className="mt-[13.5px] pb-[13.5px] border rounded-[20px]"
                      >
                        {console.log(
                          "item?.product_details?.image || item?.product_details?.image?.link"
                        )}
                        <div className="bg-white rounded-[20px] p-[15px] md:p-[20px]">
                          <div className=" rounded-[20px]">
                            <div className="md:px-[20px] py-[23px] flex flex-row gap-[10px] md:gap-[20px] ">
                              <Image
                                src={
                                  typeof item?.product_details?.image ===
                                  "object"
                                    ? item?.product_details?.image?.link
                                    : item?.product_details?.image
                                }
                                height={100}
                                width={100}
                                className="!h-[100px] !w-[100px] md:!h-[145px] md:!w-[145px]"
                                alt="product-img"
                                preview={false}
                              />
                              <div className="flex flex-col gap-[10px] md:gap-[30px] w-full ">
                                <div className="flex justify-between ">
                                  <Typography.Text className="text-[#1D1D1D] flex justify-center items-center rounded-[30px] font-[600] text-[14px] md:text-[20px] leading-[17.64px] md:leading-[25.2px]">
                                    <CutOffText
                                      maxLength={50}
                                      value={sanitizeProductName(
                                        item?.product_details?.product_name &&
                                          item?.product_details?.product_name
                                      )}
                                      className="capitalize"
                                    />
                                  </Typography.Text>
                                  <div className=" cursor-pointer text-[#fff] text-[20px] font-bold">
                                    <Image
                                      preview={false}
                                      src={
                                        item?.product_details?.is_liked
                                          ? Like
                                          : LikeIcon
                                      }
                                      alt={
                                        item?.product_details?.is_liked === true
                                          ? "LikedIcon"
                                          : "LikeIcon"
                                      }
                                      className="h-[25px] w-[25px] md:h-[30px] md:w-[30px]"
                                    />
                                  </div>
                                </div>
                                <Typography.Text className="text-[#353535] flex md:mt-[-20px] justify-start items-start rounded-[30px] font-[400] text-[12px] md:text-[14px] leading-[14px] ">
                                  <CutOffText
                                    maxLength={50}
                                    value={
                                      item?.product_details?.brand_name &&
                                      item?.product_details?.brand_name
                                    }
                                    className="capitalize"
                                  />
                                </Typography.Text>
                                <span className="flex flex-row gap-[10px]">
                                  <Typography.Text
                                    className={`text-[#fff] !font-inter rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px]`}
                                    style={{
                                      backgroundColor: getColorCode(
                                        item?.product_details.score
                                      ),
                                    }}
                                  >
                                    {`${item?.product_details.score.toFixed(
                                      1
                                    )} / 10`}
                                  </Typography.Text>
                                  <Typography.Text
                                    className="rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px] capitalize"
                                    style={{
                                      color: matchTextColor(
                                        item?.product_details?.match &&
                                          item?.product_details?.match
                                      ),
                                      backgroundColor: matchLabelColor(
                                        item?.product_details?.match &&
                                          item?.product_details?.match
                                      ),
                                    }}
                                  >
                                    {item?.product_details?.match &&
                                      item?.product_details?.match?.replace(
                                        /_/g,
                                        " "
                                      )}
                                  </Typography.Text>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-white rounded-[20px] p-[15px] md:p-[20px] mt-[20px]">
                          {(item?.display_scoring_information ||
                            item?.response_type) &&
                            item?.scoring_information &&
                            item?.scoring_information.length > 0 &&
                            item?.scoring_information?.map(
                              (section, sectionIndex) => {
                                const sectionKey = Object.keys(section)[0];

                                return (
                                  <div
                                    className="pt-[19.5px]"
                                    key={sectionIndex}
                                  >
                                    {sectionKey !== "allergens" &&
                                      Object.values(section)[0]?.subfields
                                        .length > 0 && (
                                        <div
                                          className={`flex justify-between border-b border-[#ededed] pt-[8px] pb-[17px]`}
                                        >
                                          <div className="flex flex-row gap-[10px] items-center">
                                            <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                              {Object.keys(section)[0] ==
                                              "ingredients__and__processing"
                                                ? "Ingredients"
                                                : Object.keys(
                                                    section
                                                  )[0]?.replace(/_/g, " ")}
                                            </Typography.Text>
                                          </div>
                                          <div className="flex flex-row gap-[15px]">
                                            <div className="flex flex-col gap-[5px] mr-2">
                                              <div className="flex flex-row gap-[10px] items-start">
                                                <div className="flex flex-col gap-[10px] capitalize w-[100px] lg:!w-fit">
                                                  <Typography.Text className="text-[#1D1D1D] font-[400] text-[14px] leading-[14px] text-right mt-1">
                                                    {
                                                      section[
                                                        Object.keys(section)[0]
                                                      ]?.score_text
                                                    }
                                                  </Typography.Text>
                                                  {visibleSections[
                                                    `${index}-${sectionIndex}`
                                                  ] &&
                                                  section[
                                                    Object.keys(section)[0]
                                                  ]?.per_serving ? (
                                                    <>
                                                      <div className="flex justify-start items-start">
                                                        <Typography.Text className="text-[#828282] font-[400] text-[10px] leading-[14px]">
                                                          serving size :
                                                          {section[
                                                            Object.keys(
                                                              section
                                                            )[0]
                                                          ]?.per_serving?.toFixed(
                                                            1
                                                          )}{" "}
                                                        </Typography.Text>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                                {section[
                                                  Object.keys(section)[0]
                                                ]?.score ||
                                                section[Object.keys(section)[0]]
                                                  ?.sustainable_score ? (
                                                  <Badge
                                                    status="success"
                                                    color={`${getColorCode(
                                                      section[
                                                        Object.keys(section)[0]
                                                      ]?.score ??
                                                        section[
                                                          Object.keys(
                                                            section
                                                          )[0]
                                                        ]?.sustainable_score
                                                    )}`}
                                                    size="default"
                                                  />
                                                ) : null}
                                                <span>
                                                  <Popover
                                                    content={titleContent(
                                                      section
                                                    )}
                                                    // title="title"
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                  >
                                                    <Image
                                                      alt="info"
                                                      src={info}
                                                      preview={false}
                                                      className="cursor-pointer h-[20px] w-[20px]"
                                                    />
                                                  </Popover>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="mt-1">
                                              {visibleSections[
                                                `${index}-${sectionIndex}`
                                              ] ? (
                                                <UpIcon
                                                  className="cursor-pointer"
                                                  fill="#828282"
                                                  onClick={() =>
                                                    toggleSectionVisibility(
                                                      index,
                                                      sectionIndex
                                                    )
                                                  }
                                                  width={
                                                    screenWidth < 1024
                                                      ? "12"
                                                      : "20"
                                                  }
                                                  height={
                                                    screenWidth < 1024
                                                      ? "12"
                                                      : "20"
                                                  }
                                                />
                                              ) : (
                                                <DownIcon
                                                  className="cursor-pointer"
                                                  fill="#828282"
                                                  onClick={() =>
                                                    toggleSectionVisibility(
                                                      index,
                                                      sectionIndex
                                                    )
                                                  }
                                                  width={
                                                    screenWidth < 1024
                                                      ? "12"
                                                      : "20"
                                                  }
                                                  height={
                                                    screenWidth < 1024
                                                      ? "12"
                                                      : "20"
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    <div className="">
                                      {visibleSections[
                                        `${index}-${sectionIndex}`
                                      ] &&
                                        Object.values(section)[0]?.subfields
                                          .length > 0 &&
                                        Object.values(
                                          section
                                        )[0]?.subfields?.map((item, i) => (
                                          <div
                                            key={i}
                                            className={`border-b border-[#ededed] py-[10px] ${
                                              i === 0 && "mt-[19.5px] lg:mt-0"
                                            }`}
                                          >
                                            <div className="flex justify-between">
                                              <div>
                                                {Object.keys(section)[0] !==
                                                  "nutrition" && (
                                                  <Checkbox
                                                    className="mr-[10px]"
                                                    checked={
                                                      item[Object.keys(item)[0]]
                                                        ?.checked
                                                    }
                                                  />
                                                )}
                                                <Typography.Text className="text-[#1D1D1D] font-[500] text-[12px] lg:text-[14px] leading-[20.16px] md:leading-[25.2px]">
                                                  {Object.keys(item)[0]
                                                    ?.replace(/_/g, " ")
                                                    ?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    )}
                                                </Typography.Text>
                                              </div>
                                              <div className="flex flex-row gap-[10px] items-center">
                                                <Typography.Text
                                                  className={`text-[#828282] font-[400] text-[12px] lg:text-[14px] leading-[14px] w-[120px] 
                                  ${
                                    Object.keys(section)[0] == "nutrition"
                                      ? "lg:w-[170px] text-start"
                                      : "lg:w-[500px] text-right"
                                  } `}
                                                >
                                                  {(item[Object.keys(item)[0]]
                                                    ?.quantity_details &&
                                                    item[Object.keys(item)[0]]
                                                      ?.score_text &&
                                                    `${
                                                      item[Object.keys(item)[0]]
                                                        ?.quantity_details
                                                    } | ${
                                                      item[Object.keys(item)[0]]
                                                        ?.score_text
                                                    }`) ||
                                                    (item[Object.keys(item)[0]]
                                                      ?.score_text &&
                                                      `${
                                                        item[
                                                          Object.keys(item)[0]
                                                        ]?.score_text
                                                      }`) ||
                                                    "Not Given"}
                                                </Typography.Text>
                                                <Badge
                                                  status="success"
                                                  color={`${getColorCode(
                                                    item[Object.keys(item)[0]]
                                                      ?.score
                                                  )}`}
                                                  size="default"
                                                  className=""
                                                />
                                                <span
                                                  className={`${
                                                    item[Object.keys(item)[0]]
                                                      ?.itag_template
                                                      ? "cursor-pointer"
                                                      : "opacity-0"
                                                  }`}
                                                >
                                                  <Popover
                                                    content={content(item)}
                                                    // title="title"
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                  >
                                                    <Image
                                                      src={info}
                                                      alt="info"
                                                      preview={false}
                                                      className="h-[20px] w-[20px]"
                                                    />
                                                  </Popover>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                );
                              }
                            )}

                          <div className="pt-[19.5px] flex flex-col gap-[15px]">
                            <div
                              className={`flex justify-between ${
                                visibleReviews[`${index}-reviews`] ||
                                item?.better_alternatives?.length > 0
                                  ? "lg:border-b border-[#ededed]"
                                  : ""
                              }  pt-[8px] lg:pb-[17px]`}
                            >
                              <div className="flex flex-row gap-[10px] items-center">
                                <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                  See more
                                </Typography.Text>
                              </div>
                              <div className="flex flex-row gap-[10px]">
                                {visibleReviews[`${index}-reviews`] ? (
                                  <UpIcon
                                    onClick={() =>
                                      toggleReviewVisibility(index)
                                    }
                                    // fill="#828282"
                                    fill={
                                      screenWidth < 1024 ? "#000000" : "#828282"
                                    }
                                    className="cursor-pointer"
                                    width={screenWidth < 1024 ? "12" : "20"}
                                    height={screenWidth < 1024 ? "12" : "20"}
                                  />
                                ) : (
                                  <DownIcon
                                    onClick={() =>
                                      toggleReviewVisibility(index)
                                    }
                                    // fill="#828282"
                                    fill={
                                      screenWidth < 1024 ? "#000000" : "#828282"
                                    }
                                    className="cursor-pointer"
                                    width={screenWidth < 1024 ? "12" : "20"}
                                    height={screenWidth < 1024 ? "12" : "20"}
                                  />
                                )}
                              </div>
                            </div>
                            {visibleReviews[`${index}-reviews`] && (
                              <>
                                <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                  <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[25.2px] md:leading-[25.2px]">
                                    Rating on Amazon
                                  </Typography.Text>
                                  <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                    {`${item?.product_details?.rating} / ${item?.product_details?.total_number_of_ratings} `}
                                    reviews
                                  </Typography.Text>
                                </div>
                                <div className="flex flex-col items-start gap-[18px] border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                  <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[25.2px] md:leading-[25.2px]">
                                    Review Summary
                                  </Typography.Text>
                                  <Typography.Text className="text-[#666666] font-[400] text-[14px] leading-[22px]">
                                    {item?.product_details?.reviews_summary}
                                  </Typography.Text>
                                </div>
                                <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                  <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[22.68px] md:leading-[22.68px]">
                                    Buy Now on Amazon
                                  </Typography.Text>
                                  <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                    <RightIcon className="cursor-pointer" />
                                  </Typography.Text>
                                </div>
                                <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                  <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[22.68px] md:leading-[22.68px]">
                                    Buy Now on Instacart
                                  </Typography.Text>
                                  <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                    <RightIcon className="cursor-pointer" />
                                  </Typography.Text>
                                </div>
                              </>
                            )}
                          </div>

                          {item?.better_alternatives?.length > 0 ? (
                            <>
                              {" "}
                              <div className="pt-[19.5px] flex flex-col gap-[15px]">
                                <div
                                  className={`flex justify-between lg:border-b border-[#ededed] pt-[8px] lg:pb-[17px]`}
                                >
                                  <div className="flex flex-row gap-[10px] items-center">
                                    <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                      Better Alternatives
                                    </Typography.Text>
                                  </div>
                                  <div className="flex flex-row gap-[10px]"></div>
                                </div>

                                <div>
                                  <div
                                    className="!w-full pt-[20px] flex flex-row lg:justify-center items-center overflow-x-auto gap-[21px]"
                                    style={{
                                      scrollbarWidth: "none",
                                    }}
                                  >
                                    {item?.better_alternatives?.map(
                                      (val, i) => {
                                        return (
                                          <div
                                            key={i}
                                            className="w-full min-w-[156px] flex flex-col gap-[15px] items-center"
                                          >
                                            <Image
                                              preview={false}
                                              src={val?.image}
                                              className="h-[150px] w-[130px] md:h-[150px] md:w-[130px]"
                                              width={100}
                                              height={100}
                                              alt="product-img"
                                            />
                                            <Typography.Text className="capitalize font-normal text-[12px] md:text-[16px] leading-[16px] md:leading-[22px] text-center text-[#1D1D1D]">
                                              {val?.product_name?.replace(
                                                /_/g,
                                                " "
                                              )}
                                            </Typography.Text>
                                            <Typography.Text
                                              className={`text-[#fff] rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px]`}
                                              style={{
                                                backgroundColor: getColorCode(
                                                  val.score
                                                ),
                                              }}
                                            >
                                              {`${val?.score?.toFixed(1)} / 10`}
                                            </Typography.Text>
                                            <Typography.Text
                                              className="rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px] capitalize"
                                              style={{
                                                color: matchTextColor(
                                                  val?.match
                                                ),
                                                backgroundColor:
                                                  matchLabelColor(val?.match),
                                              }}
                                            >
                                              {(val?.match).replace(/_/g, " ")}
                                            </Typography.Text>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  {isScoredInProgress && (
                    <>
                      <div className="w-full cursor-pointer flex justify-between mb-3">
                        <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                          {inPutTypeBarcode
                            ? `Score ${
                                item?.barcode_unique_id ||
                                item?.response_scoring?.barcode_unique_id
                              }`
                            : item?.brand_name || item?.product_name
                            ? `Score ${[item?.brand_name, item?.product_name]
                                ?.filter(Boolean)
                                ?.join(" & ")}`
                            : item?.user_message || "Score Product"}
                        </Typography.Text>
                      </div>
                      <div
                        className="cursor-pointer flex items-center gap-4"
                        // onClick={() => router.push("/history")}
                      >
                        <span> In progress</span>
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="default"
                        />
                      </div>
                    </>
                  )}

                  {isScoredPending && (
                    <>
                      <div className="w-full cursor-pointer flex justify-between mb-3">
                        <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                          {inPutTypeBarcode
                            ? `Score ${
                                item?.barcode_unique_id ||
                                item?.response_scoring?.barcode_unique_id
                              }`
                            : item?.brand_name || item?.product_name
                            ? `Score ${[item?.brand_name, item?.product_name]
                                ?.filter(Boolean)
                                ?.join(" & ")}`
                            : item?.user_message || "Score Product"}
                        </Typography.Text>
                      </div>
                      <div className="cursor-pointer bg-white border-b-[#e5e5e5] font-[500] text-[18px] leading-[22.68px] border-b pb-[13.5px]">
                        <Typography.Text className="text-[#353535] font-[400] text-[14px] leading-[14px]">
                          Scoring still in-progress due to missing info, pls
                          check in 48 hours.
                        </Typography.Text>
                      </div>
                    </>
                  )}

                  {isScoredFailed && (
                    <>
                      <div className="w-full cursor-pointer flex justify-between mb-3">
                        <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                          {inPutTypeBarcode
                            ? `Score ${
                                item?.barcode_unique_id ||
                                item?.response_scoring?.barcode_unique_id
                              }`
                            : item?.brand_name || item?.product_name
                            ? `Score ${[item?.brand_name, item?.product_name]
                                ?.filter(Boolean)
                                ?.join(" & ")}`
                            : item?.user_message || "Score Product"}
                        </Typography.Text>
                      </div>
                      <div className="cursor-pointer bg-white border-b-[#e5e5e5] font-[500] text-[18px] leading-[22.68px] border-b pb-[13.5px]">
                        <Typography.Text className="text-[#353535] font-[400] text-[14px] leading-[14px]">
                          Unable to score. couldn&apos;t find enough info.
                        </Typography.Text>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div key={index}>
                  {
                    isTextScored &&
                      item?.display_scoring_information === true && (
                        <div className="my-3">
                          <div
                            key={index}
                            className="mt-[13.5px] pb-[13.5px] border rounded-[20px]"
                          >
                            {console.log(
                              "item?.product_details?.image || item?.product_details?.image?.link"
                            )}
                            <div className="bg-white rounded-[20px] p-[15px] md:p-[20px]">
                              <div className=" rounded-[20px]">
                                <div className="md:px-[20px] py-[23px] flex flex-row gap-[10px] md:gap-[20px] ">
                                  <Image
                                    src={
                                      typeof item?.product_details?.image ===
                                      "object"
                                        ? item?.product_details?.image?.link
                                        : item?.product_details?.image
                                    }
                                    height={100}
                                    width={100}
                                    className="!h-[100px] !w-[100px] md:!h-[145px] md:!w-[145px]"
                                    alt="product-img"
                                    preview={false}
                                  />
                                  <div className="flex flex-col gap-[10px] md:gap-[30px] w-full ">
                                    <div className="flex justify-between ">
                                      <Typography.Text className="text-[#1D1D1D] flex justify-center items-center rounded-[30px] font-[600] text-[14px] md:text-[20px] leading-[17.64px] md:leading-[25.2px]">
                                        <CutOffText
                                          maxLength={50}
                                          value={sanitizeProductName(
                                            item?.product_details
                                              ?.product_name &&
                                              item?.product_details
                                                ?.product_name
                                          )}
                                          className="capitalize"
                                        />
                                      </Typography.Text>
                                      <div className=" cursor-pointer text-[#fff] text-[20px] font-bold">
                                        <Image
                                          preview={false}
                                          src={
                                            item?.product_details?.is_liked
                                              ? Like
                                              : LikeIcon
                                          }
                                          alt={
                                            item?.product_details?.is_liked ===
                                            true
                                              ? "LikedIcon"
                                              : "LikeIcon"
                                          }
                                          className="h-[25px] w-[25px] md:h-[30px] md:w-[30px]"
                                        />
                                      </div>
                                    </div>
                                    <Typography.Text className="text-[#353535] flex md:mt-[-20px] justify-start items-start rounded-[30px] font-[400] text-[12px] md:text-[14px] leading-[14px] ">
                                      <CutOffText
                                        maxLength={50}
                                        value={
                                          item?.product_details?.brand_name &&
                                          item?.product_details?.brand_name
                                        }
                                        className="capitalize"
                                      />
                                    </Typography.Text>
                                    <span className="flex flex-row gap-[10px]">
                                      <Typography.Text
                                        className={`text-[#fff] !font-inter rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px]`}
                                        style={{
                                          backgroundColor: getColorCode(
                                            item?.product_details.score
                                          ),
                                        }}
                                      >
                                        {`${item?.product_details.score.toFixed(
                                          1
                                        )} / 10`}
                                      </Typography.Text>
                                      <Typography.Text
                                        className="rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px] capitalize"
                                        style={{
                                          color: matchTextColor(
                                            item?.product_details?.match &&
                                              item?.product_details?.match
                                          ),
                                          backgroundColor: matchLabelColor(
                                            item?.product_details?.match &&
                                              item?.product_details?.match
                                          ),
                                        }}
                                      >
                                        {item?.product_details?.match &&
                                          item?.product_details?.match?.replace(
                                            /_/g,
                                            " "
                                          )}
                                      </Typography.Text>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="bg-white rounded-[20px] p-[15px] md:p-[20px] mt-[20px]">
                              {(item?.display_scoring_information ||
                                item?.response_type) &&
                                item?.scoring_information &&
                                item?.scoring_information.length > 0 &&
                                item?.scoring_information?.map(
                                  (section, sectionIndex) => {
                                    const sectionKey = Object.keys(section)[0];

                                    return (
                                      <div
                                        className="pt-[19.5px]"
                                        key={sectionIndex}
                                      >
                                        {sectionKey !== "allergens" &&
                                          Object.values(section)[0]?.subfields
                                            .length > 0 && (
                                            <div
                                              className={`flex justify-between border-b border-[#ededed] pt-[8px] pb-[17px]`}
                                            >
                                              <div className="flex flex-row gap-[10px] items-center">
                                                <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                                  {/* eslint-disable-next-line */}
                                                  {Object.keys(section)[0] ==
                                                  "ingredients__and__processing"
                                                    ? "Ingredients"
                                                    : Object.keys(
                                                        section
                                                      )[0]?.replace(/_/g, " ")}
                                                </Typography.Text>
                                              </div>
                                              <div className="flex flex-row gap-[15px]">
                                                <div className="flex flex-col gap-[5px] mr-2">
                                                  <div className="flex flex-row gap-[10px] items-start">
                                                    <div className="flex flex-col gap-[10px] capitalize w-[100px] lg:!w-fit">
                                                      <Typography.Text className="text-[#1D1D1D] font-[400] text-[14px] leading-[14px] text-right mt-1">
                                                        {
                                                          section[
                                                            Object.keys(
                                                              section
                                                            )[0]
                                                          ]?.score_text
                                                        }
                                                      </Typography.Text>
                                                      {visibleSections[
                                                        `${index}-${sectionIndex}`
                                                      ] &&
                                                      section[
                                                        Object.keys(section)[0]
                                                      ]?.per_serving ? (
                                                        <>
                                                          <div className="flex justify-start items-start">
                                                            <Typography.Text className="text-[#828282] font-[400] text-[10px] leading-[14px]">
                                                              serving size :
                                                              {section[
                                                                Object.keys(
                                                                  section
                                                                )[0]
                                                              ]?.per_serving?.toFixed(
                                                                1
                                                              )}{" "}
                                                            </Typography.Text>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                    {section[
                                                      Object.keys(section)[0]
                                                    ]?.score ||
                                                    section[
                                                      Object.keys(section)[0]
                                                    ]?.sustainable_score ? (
                                                      <Badge
                                                        status="success"
                                                        color={`${getColorCode(
                                                          section[
                                                            Object.keys(
                                                              section
                                                            )[0]
                                                          ]?.score ??
                                                            section[
                                                              Object.keys(
                                                                section
                                                              )[0]
                                                            ]?.sustainable_score
                                                        )}`}
                                                        size="default"
                                                      />
                                                    ) : null}
                                                    <span>
                                                      <Popover
                                                        content={titleContent(
                                                          section
                                                        )}
                                                        // title="title"
                                                        trigger="click"
                                                        placement="bottomLeft"
                                                      >
                                                        <Image
                                                          alt="info"
                                                          src={info}
                                                          preview={false}
                                                          className="cursor-pointer h-[20px] w-[20px]"
                                                        />
                                                      </Popover>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="mt-1">
                                                  {visibleSections[
                                                    `${index}-${sectionIndex}`
                                                  ] ? (
                                                    <UpIcon
                                                      className="cursor-pointer"
                                                      fill="#828282"
                                                      onClick={() =>
                                                        toggleSectionVisibility(
                                                          index,
                                                          sectionIndex
                                                        )
                                                      }
                                                      width={
                                                        screenWidth < 1024
                                                          ? "12"
                                                          : "20"
                                                      }
                                                      height={
                                                        screenWidth < 1024
                                                          ? "12"
                                                          : "20"
                                                      }
                                                    />
                                                  ) : (
                                                    <DownIcon
                                                      className="cursor-pointer"
                                                      fill="#828282"
                                                      onClick={() =>
                                                        toggleSectionVisibility(
                                                          index,
                                                          sectionIndex
                                                        )
                                                      }
                                                      width={
                                                        screenWidth < 1024
                                                          ? "12"
                                                          : "20"
                                                      }
                                                      height={
                                                        screenWidth < 1024
                                                          ? "12"
                                                          : "20"
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        <div className="">
                                          {visibleSections[
                                            `${index}-${sectionIndex}`
                                          ] &&
                                            Object.values(section)[0]?.subfields
                                              .length > 0 &&
                                            Object.values(
                                              section
                                            )[0]?.subfields?.map((item, i) => (
                                              <div
                                                key={i}
                                                className={`border-b border-[#ededed] py-[10px] ${
                                                  i === 0 &&
                                                  "mt-[19.5px] lg:mt-0"
                                                }`}
                                              >
                                                <div className="flex justify-between">
                                                  <div>
                                                    {Object.keys(section)[0] !==
                                                      "nutrition" && (
                                                      <Checkbox
                                                        className="mr-[10px]"
                                                        checked={
                                                          item[
                                                            Object.keys(item)[0]
                                                          ]?.checked
                                                        }
                                                      />
                                                    )}
                                                    <Typography.Text className="text-[#1D1D1D] font-[500] text-[12px] lg:text-[14px] leading-[20.16px] md:leading-[25.2px]">
                                                      {Object.keys(item)[0]
                                                        ?.replace(/_/g, " ")
                                                        ?.replace(
                                                          /\b\w/g,
                                                          (char) =>
                                                            char?.toUpperCase()
                                                        )}
                                                    </Typography.Text>
                                                  </div>
                                                  <div className="flex flex-row gap-[10px] items-center">
                                                    <Typography.Text
                                                      className={`text-[#828282] font-[400] text-[12px] lg:text-[14px] leading-[14px] w-[120px] 
                                    ${
                                      //  eslint-disable-next-line
                                      Object.keys(section)[0] == "nutrition"
                                        ? "lg:w-[170px] text-start"
                                        : "lg:w-[500px] text-right"
                                    } `}
                                                    >
                                                      {(item[
                                                        Object.keys(item)[0]
                                                      ]?.quantity_details &&
                                                        item[
                                                          Object.keys(item)[0]
                                                        ]?.score_text &&
                                                        `${
                                                          item[
                                                            Object.keys(item)[0]
                                                          ]?.quantity_details
                                                        } | ${
                                                          item[
                                                            Object.keys(item)[0]
                                                          ]?.score_text
                                                        }`) ||
                                                        (item[
                                                          Object.keys(item)[0]
                                                        ]?.score_text &&
                                                          `${
                                                            item[
                                                              Object.keys(
                                                                item
                                                              )[0]
                                                            ]?.score_text
                                                          }`) ||
                                                        "Not Given"}
                                                    </Typography.Text>
                                                    <Badge
                                                      status="success"
                                                      color={`${getColorCode(
                                                        item[
                                                          Object.keys(item)[0]
                                                        ]?.score
                                                      )}`}
                                                      size="default"
                                                      className=""
                                                    />
                                                    <span
                                                      className={`${
                                                        item[
                                                          Object.keys(item)[0]
                                                        ]?.itag_template
                                                          ? "cursor-pointer"
                                                          : "opacity-0"
                                                      }`}
                                                    >
                                                      <Popover
                                                        content={content(item)}
                                                        // title="title"
                                                        trigger="click"
                                                        placement="bottomLeft"
                                                      >
                                                        <Image
                                                          src={info}
                                                          alt="info"
                                                          preview={false}
                                                          className="h-[20px] w-[20px]"
                                                        />
                                                      </Popover>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                              <div className="pt-[19.5px] flex flex-col gap-[15px]">
                                <div
                                  className={`flex justify-between ${
                                    visibleReviews[`${index}-reviews`] ||
                                    item?.better_alternatives?.length > 0
                                      ? "lg:border-b border-[#ededed]"
                                      : ""
                                  }  pt-[8px] lg:pb-[17px]`}
                                >
                                  <div className="flex flex-row gap-[10px] items-center">
                                    <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                      See more
                                    </Typography.Text>
                                  </div>
                                  <div className="flex flex-row gap-[10px]">
                                    {visibleReviews[`${index}-reviews`] ? (
                                      <UpIcon
                                        onClick={() =>
                                          toggleReviewVisibility(index)
                                        }
                                        // fill="#828282"
                                        fill={
                                          screenWidth < 1024
                                            ? "#000000"
                                            : "#828282"
                                        }
                                        className="cursor-pointer"
                                        width={screenWidth < 1024 ? "12" : "20"}
                                        height={
                                          screenWidth < 1024 ? "12" : "20"
                                        }
                                      />
                                    ) : (
                                      <DownIcon
                                        onClick={() =>
                                          toggleReviewVisibility(index)
                                        }
                                        // fill="#828282"
                                        fill={
                                          screenWidth < 1024
                                            ? "#000000"
                                            : "#828282"
                                        }
                                        className="cursor-pointer"
                                        width={screenWidth < 1024 ? "12" : "20"}
                                        height={
                                          screenWidth < 1024 ? "12" : "20"
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                {visibleReviews[`${index}-reviews`] && (
                                  <>
                                    <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                      <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[25.2px] md:leading-[25.2px]">
                                        Rating on Amazon
                                      </Typography.Text>
                                      <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                        {`${item?.product_details?.rating} / ${item?.product_details?.total_number_of_ratings} `}
                                        reviews
                                      </Typography.Text>
                                    </div>
                                    <div className="flex flex-col items-start gap-[18px] border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                      <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[25.2px] md:leading-[25.2px]">
                                        Review Summary
                                      </Typography.Text>
                                      <Typography.Text className="text-[#666666] font-[400] text-[14px] leading-[22px]">
                                        {item?.product_details?.reviews_summary}
                                      </Typography.Text>
                                    </div>
                                    <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                      <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[22.68px] md:leading-[22.68px]">
                                        Buy Now on Amazon
                                      </Typography.Text>
                                      <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                        <RightIcon className="cursor-pointer" />
                                      </Typography.Text>
                                    </div>
                                    <div className="flex justify-between items-center border-b border-[#ededed] lg:border-none lg:bg-[#F7F7F7] lg:rounded-[20px] pt-0 pb-[10px] lg:pt-[19px] lg:pb-[19px] lg:px-[20px]">
                                      <Typography.Text className="text-[#1D1D1D] font-[600] lg:font-[700] text-[14px] lg:text-[18px] leading-[22.68px] md:leading-[22.68px]">
                                        Buy Now on Instacart
                                      </Typography.Text>
                                      <Typography.Text className="text-[#353535] font-[400] lg:font-[500] text-[12px] lg:text-[16px] leading-[14px]">
                                        <RightIcon className="cursor-pointer" />
                                      </Typography.Text>
                                    </div>
                                  </>
                                )}
                              </div>

                              {item?.better_alternatives?.length > 0 ? (
                                <>
                                  {" "}
                                  <div className="pt-[19.5px] flex flex-col gap-[15px]">
                                    <div
                                      className={`flex justify-between lg:border-b border-[#ededed] pt-[8px] lg:pb-[17px]`}
                                    >
                                      <div className="flex flex-row gap-[10px] items-center">
                                        <Typography.Text className="capitalize text-[#006747] font-[600] text-[14px] md:text-[16px] leading-[20.16px] md:leading-[25.2px]">
                                          Better Alternatives
                                        </Typography.Text>
                                      </div>
                                      <div className="flex flex-row gap-[10px]"></div>
                                    </div>

                                    <div>
                                      <div
                                        className="!w-full pt-[20px] flex flex-row lg:justify-center items-center overflow-x-auto gap-[21px]"
                                        style={{
                                          scrollbarWidth: "none",
                                        }}
                                      >
                                        {item?.better_alternatives?.map(
                                          (val, i) => {
                                            return (
                                              <div
                                                key={i}
                                                className="w-full min-w-[156px] flex flex-col gap-[15px] items-center"
                                              >
                                                <Image
                                                  preview={false}
                                                  src={val?.image}
                                                  className="h-[150px] w-[130px] md:h-[150px] md:w-[130px]"
                                                  width={100}
                                                  height={100}
                                                  alt="product-img"
                                                />
                                                <Typography.Text className="capitalize font-normal text-[12px] md:text-[16px] leading-[16px] md:leading-[22px] text-center text-[#1D1D1D]">
                                                  {val?.product_name?.replace(
                                                    /_/g,
                                                    " "
                                                  )}
                                                </Typography.Text>
                                                <Typography.Text
                                                  className={`text-[#fff] rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px]`}
                                                  style={{
                                                    backgroundColor:
                                                      getColorCode(val.score),
                                                  }}
                                                >
                                                  {`${val?.score?.toFixed(
                                                    1
                                                  )} / 10`}
                                                </Typography.Text>
                                                <Typography.Text
                                                  className="rounded-[30px] font-bold text-[12px] md:text-[16px] leading-[19.36px] text-center px-[10px] py-[5px] capitalize"
                                                  style={{
                                                    color: matchTextColor(
                                                      val?.match
                                                    ),
                                                    backgroundColor:
                                                      matchLabelColor(
                                                        val?.match
                                                      ),
                                                  }}
                                                >
                                                  {(val?.match).replace(
                                                    /_/g,
                                                    " "
                                                  )}
                                                </Typography.Text>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    // : (
                    //   <>
                    //     sdsdsdsds
                    //     <div className="w-full cursor-pointer flex justify-between mb-3">
                    //       <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                    //         {item?._id}
                    //       </Typography.Text>
                    //     </div>
                    //     <div className="cursor-pointer bg-white border-b-[#e5e5e5] font-[500] text-[18px] leading-[22.68px] border-b pb-[13.5px]">
                    //       <Typography.Text className="text-[#353535] font-[400] text-[14px] leading-[14px]">
                    //         {item?.response_text}
                    //       </Typography.Text>
                    //     </div>
                    //   </>
                    // )
                  }

                  {isTextScoredInProgress &&
                    (item?.product_name ||
                      item?.brand_name ||
                      item?.user_message ||
                      item?.product_details?.product_name) && (
                      <>
                        <div className="w-full cursor-pointer flex justify-between mb-3">
                          <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                            {inPutTypeBarcode
                              ? `Score ${
                                  item?.barcode_unique_id ||
                                  item?.response_scoring?.barcode_unique_id
                                }`
                              : item?.brand_name || item?.product_name
                              ? `Score ${[item?.brand_name, item?.product_name]
                                  ?.filter(Boolean)
                                  ?.join(" & ")}`
                              : item?.user_message || "Score Product"}
                          </Typography.Text>
                        </div>
                        <div
                          className="cursor-pointer flex items-center gap-4"
                          // onClick={() => router.push("/history")}
                        >
                          <span> In progress</span>
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size="default"
                          />
                        </div>
                      </>
                    )}

                  {isTextScoredPending &&
                    (item?.product_name ||
                      item?.brand_name ||
                      item?.user_message ||
                      item?.product_details?.product_name) && (
                      <>
                        <div className="w-full cursor-pointer flex justify-between mb-3">
                          <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                            {inPutTypeBarcode
                              ? `Score ${
                                  item?.barcode_unique_id ||
                                  item?.response_scoring?.barcode_unique_id
                                }`
                              : item?.brand_name || item?.product_name
                              ? `Score ${[item?.brand_name, item?.product_name]
                                  ?.filter(Boolean)
                                  ?.join(" & ")}`
                              : item?.user_message || "Score Product"}
                          </Typography.Text>
                        </div>
                        <div className="cursor-pointer bg-white border-b-[#e5e5e5] font-[500] text-[18px] leading-[22.68px] border-b pb-[13.5px]">
                          <Typography.Text className="text-[#353535] font-[400] text-[14px] leading-[14px]">
                            Scoring still in-progress due to missing info, pls
                            check in 48 hours.
                          </Typography.Text>
                        </div>
                      </>
                    )}
                  {isTextScoredFailed &&
                    (item?.product_name ||
                      item?.brand_name ||
                      item?.user_message ||
                      item?.product_details?.product_name) && (
                      <>
                        <div className="w-full cursor-pointer flex justify-between mb-3">
                          <Typography.Text className="break-words font-normal text-[16px] leading-[24.11px] text-start">
                            {inPutTypeBarcode
                              ? `Score ${
                                  item?.barcode_unique_id ||
                                  item?.response_scoring?.barcode_unique_id
                                }`
                              : item?.brand_name || item?.product_name
                              ? `Score ${[item?.brand_name, item?.product_name]
                                  ?.filter(Boolean)
                                  ?.join(" & ")}`
                              : item?.user_message || "Score Product"}
                          </Typography.Text>
                        </div>
                        <div className="cursor-pointer bg-white border-b-[#e5e5e5] font-[500] text-[18px] leading-[22.68px] border-b pb-[13.5px]">
                          <Typography.Text className="text-[#353535] font-[400] text-[14px] leading-[14px]">
                            {item?.response_text
                              ? item?.response_text
                              : "Unable to score. couldn&apos;t find enough info."}
                          </Typography.Text>
                        </div>
                      </>
                    )}
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ViewProductDetails;
